import React, { useState } from "react";
import styled from "styled-components";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import ImageSlider from "./Components/HeroSection";
import MainData from "./Components/MainData";
import Services from "./Components/Services";
import { getData } from "./firebase/firebasemethod";
import { Container } from "@mui/material";
import SliderSecII from "./Components/sliderSecII.js";
import TaggboxWidget from './Components/ReviewSlider.js'
import MainData1 from "./Components/MainData copy.js";
import MainData2 from "./Components/MainData copy 2.js";

const Home = () => {

  const [Data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
  };

  const data = ()=>{
    getData('Review').then((res)=>{
      setData(Object.values(res))
    }).catch((err)=>{
      // console.log(err)
    })
  }

  data()

  return (
   <>
    
      <>
      <Header />
      <ImageSlider name={'Website'}/>
    <Services />

    <div >

      <div style={{backgroundColor:'#f8f9fa', margin:'3rem 0', padding:'2rem'}}>
        <Container>
        <h3 className="kufi">Best Kufi Hats, Muslim Headwear</h3>
       
        <SliderSecII />
        </Container>
      </div>

    

    </div>



    
    <MainData />

     
     <TaggboxWidget img_link='images/Caps.jpg'/>

     <MainData1 />

     
<TaggboxWidget img_link='images/Attar.jpg'/>

<MainData2 />

     
<TaggboxWidget img_link='images/Abaya.jpg'/>

    <Footer />
    </>
   
    
    </>
    )
};

const Wrapper = styled.section`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.bg};
`



export default Home;