import React from 'react'
import styled from 'styled-components'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'

const Return = () => {
  return (
    <div style={{marginTop:120}}>
        <Navbar />
        <Returnstyle>
        <div className="container ">
        <h3 className="return text-center" style={{fontSize:25,marginTop:35}}>Return & Exchange Policy</h3> <br /> <br />
        <ul className="fs-5 ">
            <li>No cash refunds.
            </li>
            <li className="cash">You can request an exchange by sending us an email at <a style={{color:'black',fontWeight:'bold'}} href="mailto:sikandersmodestile@gmail.com">info@modestile.pk</a>  along with your Order
                ID. Product (s) can also be exchanged from the Modestile Online Store within 07 days from the date of
                purchase.</li>
            <li>Please note that the product must be unused and unaltered with the price label intact along with the purchase receipt..</li>
        </ul> <br /> <br />
    </div>
    </Returnstyle>
    <Footer />
    </div>
  )
}

export default Return


const Returnstyle = styled.section`
li{
    font-size: 20px;
    margin-top: 20px;
    list-style: circle;
}

`
