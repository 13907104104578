import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './slider.css'

const SliderSecII = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 6 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 426 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 426, min: 0 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    }
  };


  return (
    <div>
      <Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="transform 2s"
  transitionDuration={500}
 // containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-20-px"
  arrows={false}
>



  <div className='part1'>
    <img  src='images/kamal1.jpeg' alt='Kamal'/>
  </div>
  <div className='part1'>
  <img src='images/kamal2.jpeg' alt='Kamal'/>
  </div>
  <div className='part1'>
  <img src='images/kamal3.jpeg'alt='Kamal'/>
  </div>
  <div className='part1'>
  <img src='images/kamal4.jpeg' alt='Kamal'/>
  </div>
  <div className='part1'>
  <img src='images/kamal5.jpeg' alt='Kamal'/>
  </div>
  <div className='part1'>
  <img src='images/kamal6.jpeg' alt='Kamal'/>
  </div>


  <div className='part1'>
  <img src='images/Badar Cap SN.png' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Dirham Cap.JPG' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/habib.JPG' alt='Umar Cap'/>
  </div >
  <div className='part1'>
  <img src='images/Inaam.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/khalifa.JPG' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/modest.JPG' alt='Umar Cap'/>
  </div>

  <div className='part1'>
  <img src='images/Inayat.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Khutba.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Nawaz.jpg' alt='Umar Cap'/>
  </div >
  <div className='part1'>
  <img src='images/Nishata.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/nusrat.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/qamar.jpg' alt='Umar Cap'/>
  </div>


  <div className='part1'>
  <img src='images/Rashid.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Rukana (1).jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/salama.JPG' alt='Umar Cap'/>
  </div >
  <div className='part1'>
  <img src='images/Salman Cap.png' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/shujaat.JPG' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Sirya Cap sn.jpg' alt='Umar Cap'/>
  </div>

  <div className='part1'>
  <img src='images/Suraqa.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Umar Cap.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/wasif.JPG' alt='Umar Cap'/>
  </div >
  <div className='part1'>
  <img src='images/yahya.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Younus.jpg' alt='Umar Cap'/>
  </div>
  <div className='part1'>
  <img src='images/Tehseen.jpg' alt='Umar Cap'/>
  </div>

  {/* <div className='part1'>
  <img src='images/sirya1.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya2.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya3.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya4.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya5.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya6.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya7.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya8.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya9.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya10.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya11.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya12.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya13.JPG'/>
  </div>
  <div className='part1'>
  <img src='images/sirya14.JPG'/>
  </div>

  <div className='part1'>
  <img src='images/umar7.jpeg'/>
  </div>
  <div className='part1'>
  <img src='images/umar8.jpeg'/>
  </div>
  <div className='part1'>
  <img src='images/umar9.jpeg'/>
  </div>
  <div className='part1'>
  <img src='images/umar10.jpeg'/>
  </div>
 

  <div className='part1'>
  <img src='images/nafasat1.jpeg'/>
  </div>
  <div className='part1'>
  <img src='images/nafasat1.jpeg'/>
  </div>
  <div className='part1'>
  <img src='images/nafasat1.jpeg'/>
  </div>
  <div className='part1'>
  <img src='images/nafasat1.jpeg'/>
  </div> */}




  
 


 
 
</Carousel>;
    </div>
    
  )
}

export default SliderSecII
