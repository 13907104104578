import React, { useEffect } from 'react';

const FacebookPixel = ({ pixelId }) => {
  useEffect(() => {
    const loadFbq = () => {
      if (!window.fbq) {
        (function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      }
    };

    const initializeFacebookPixel = () => {
      if (window.fbq) {
        window.fbq('init', pixelId);
        window.fbq('track', 'PageView');
      } else {
        console.log('Facebook Pixel not loaded.');
      }
    };

    // Load Facebook Pixel script when component mounts
    loadFbq();

    // Initialize Facebook Pixel when script is loaded
    initializeFacebookPixel();

    // Cleanup function to remove event listener (optional)
    return () => {
      // No cleanup needed for this example
    };
  }, [pixelId]);

  if (!pixelId) {
    console.warn('Invalid PixelID: null');
      
  }

  // Function to track AddToCart event
  const trackAddToCart = () => {
    if (window.fbq) {
      window.fbq('track', 'AddToCart');
    }
  };

   // Function to track AddToCart event
   const trackViewContent = (contentId, contentName, contentType, value) => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', {
        content_ids: [contentId],
        content_name: contentName,
        content_type: contentType,
        value: value,
        currency: 'PKR'
      });
    }
  };

  // Function to track Purchase event
  const trackPurchase = (value, currency) => {
    if (window.fbq) {
      window.fbq('track', 'Purchase', { value, currency });
    }
  };

  // Return an array with trackAddToCart and trackPurchase functions
  return [trackAddToCart, trackPurchase, trackViewContent];
};

export default FacebookPixel;
