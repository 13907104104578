import { createContext, useContext, useEffect, useReducer } from "react";
import AddToCart from "../Components/AddToCart";
import reducer from '../reducer/cartReducer'
import ReactPixel from 'react-facebook-pixel';

const CartContext = createContext();



const getLocalCartData = ()=>{
    let localCart = localStorage.getItem('AddToCart')

    if(localCart == []){
        return [];
    }else{
        return JSON.parse(localCart);
    }
}




const initialState = {
    // cart:[],
    cart: getLocalCartData(),
    total_item:'',
    total_amount:'',
    shipping_fee: 250,
}

if(initialState.cart == null){
      
    initialState.cart = [];
  
}else{
    getLocalCartData();
}



console.log(initialState.cart)

const CartProvider = ({children}) =>{

    const [state, dispatch] = useReducer(reducer, initialState)

    const addToCart = (id,  color, amount, product, size,category) => {
        if(category == 'Skin Care'){
       dispatch({ type: 'ADD_TO_CART', payload: { id, amount, product } });
        }else{
            if (!id || !color|| !amount || !product || !size) {
                alert('Add Color');
                return;
              } else {
                dispatch({ type: 'ADD_TO_CART', payload: { id,  color, amount, product, size } });
              }
        }
        
      };


   


   const removeItem = (id)=>{
    dispatch({type:'REMOVE_ITEM', payload: id})
   }

const clearCart = ()=>{
    dispatch({type:'CLEAR_CART'});
};

useEffect(() => {
    dispatch({ type: "CART_ITEM_PRICE_TOTAL" });
    localStorage.setItem('AddToCart', JSON.stringify(state.cart))
    if (state.cart.length > 0) {
        const content_ids = state.cart.map(item => item.id); // Adjust as per your data structure
        const content_type = state.cart.map(item=> item.name); // Assuming individual products are tracked
  
        ReactPixel.track('AddToCart', {
          content_ids: content_ids,
          content_type: content_type,
        });
      }
    
}, [state.cart])


console.log(state.cart)

    return <CartContext.Provider value={{...state, addToCart, clearCart, removeItem}}>{children}</CartContext.Provider>
}

const useCartContext = ()=>{
    return useContext(CartContext)
}

export {CartProvider, useCartContext}