// import React, { useState } from 'react'
// import { NavLink } from 'react-router-dom';
// import styled from 'styled-components'
// import { FiShoppingCart } from "react-icons/fi";
// import { CgClose, CgMenu } from "react-icons/cg";
// import { Box } from '@mui/material';
// import { useCartContext } from '../context/cartContext';

// const Navbar = () => {
//   const {cart} = useCartContext();
//   const [active, setActive] = useState()

 
//   return (
//     <>
//     <Nav style={{background:'#edc531'}}>
//         <div className={active? "navbar active": "navbar"}>
//             <Box sx={{ display:'flex', justifyContent:'space-between'}}>
//               <Box>
//                 <img src='images/logo.jpeg' width='25%' alt='Logo' />
//               </Box>
//               <Box>

              
//             <ul className='navbar-lists'>
//                 <li>
//                     <NavLink to='/' className='navbar-link home-link' onClick={()=>setActive(false)}>Home</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/about' className='navbar-link ' onClick={()=>setActive(false)}>About</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/product' className='navbar-link ' onClick={()=>setActive(false)}>Product</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/contact' className='navbar-link ' onClick={()=>setActive(false)}>Contact</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/cart' className='navbar-link cart-trolley--link'>
//                         <FiShoppingCart className='cart-trolley'/>
//                         <span className='cart-total--item'>{cart.length}</span>
//                         </NavLink>
//                 </li>
//             </ul>
//             </Box>
//             {/* two button of open and close */}
//             <div className='mobile-navbar-btn'>
//                 <CgMenu name='menu-outline' onClick={()=>setActive(true)} className='mobile-nav-icon'/>
//                 <CgClose name='close-outline' onClick={()=>setActive(false)} className='mobile-nav-icon close-outline'/>
//             </div>
           
//             </Box>
          
//         </div>
       
//     </Nav>
//     </>
//   )
// }

// const Nav = styled.nav`
// .navbar-lists {
//   display: flex;
//   gap: 4.8rem;
//   align-items: center;
//   .navbar-link {
//     &:link,
//     &:visited {
//       display: inline-block;
//       text-decoration: none;
//       font-size: 1.8rem;
//       font-weight: 500;
//       text-transform: uppercase;
//       color: ${({ theme }) => theme.colors.black};
//       transition: color 0.3s linear;
//     }
//     &:hover,
//     &:active {
//       color: ${({ theme }) => theme.colors.helper};
//     }
//   }
// }
// .mobile-navbar-btn {
//   display: none;
//   background-color: transparent;
//   cursor: pointer;
//   border: none;
// }
// .mobile-nav-icon[name="close-outline"] {
//   display: none;
// }
// .close-outline {
//   display: none;
// }
// .cart-trolley--link {
//   position: relative;
//   .cart-trolley {
//     position: relative;
//     font-size: 3.2rem;
//   }
//   .cart-total--item {
//     width: 2.4rem;
//     height: 2.4rem;
//     position: absolute;
//     background-color: #000;
//     color: #000;
//     border-radius: 50%;
//     display: grid;
//     place-items: center;
//     top: -20%;
//     left: 70%;
//     background-color: ${({ theme }) => theme.colors.helper};
//   }
// }
// .user-login--name {
//   text-transform: capitalize;
// }
// .user-logout,
// .user-login {
//   font-size: 1.4rem;
//   padding: 0.8rem 1.4rem;
// }
// @media (max-width: ${({ theme }) => theme.media.mobile}) {
//   .mobile-navbar-btn {
//     display: inline-block;
//     z-index: 9999;
//     border: ${({ theme }) => theme.colors.black};
//     .mobile-nav-icon {
//       font-size: 4.2rem;
//       color: ${({ theme }) => theme.colors.black};
//     }
//   }
//   .active .mobile-nav-icon {
//     display: none;
//     font-size: 4.2rem;
//     position: absolute;
//     top: 30%;
//     right: 10%;
//     color: ${({ theme }) => theme.colors.black};
//     z-index: 9999;
//   }
//   .active .close-outline {
//     display: inline-block;
//   }
//   .navbar-lists {
//     width: 100vw;
//     height: 100vh;
//     position: absolute;
//     top: 0;
//     left: 0;
//     background-color: #fff;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     visibility: hidden;
//     opacity: 0;
//     transform: translateX(100%);
//     /* transform-origin: top; */
//     transition: all 0.8s linear;
//   }
//   .active .navbar-lists {
//     visibility: visible;
//     opacity: 1;
//     transform: translateX(0);
//     z-index: 999;
//     .navbar-link {
//       font-size: 4.2rem;
//     }
//   }
//   .cart-trolley--link {
//     position: relative;
//     .cart-trolley {
//       position: relative;
//       font-size: 5.2rem;
//     }
//     .cart-total--item {
//       width: 4.2rem;
//       height: 4.2rem;
//       font-size: 2rem;
//     }
//   }
//   .user-logout,
//   .user-login {
//     font-size: 2.2rem;
//     padding: 0.8rem 1.4rem;
//   }
// }
// `;

// export default Navbar


// // import React from 'react'
// // import CallIcon from '@mui/icons-material/Call';
// // import { Email } from '@mui/icons-material';
// // import './services.css'
// // import { NavLink } from 'react-router-dom';
// // import { FiShoppingCart } from "react-icons/fi";
// // import { useCartContext } from '../context/cartContext';
// // import Heart from '@mui/icons-material/Favorite';
// // import Person from '@mui/icons-material/Person';
// // import { Dropdown, NavDropdown } from 'react-bootstrap';
// // import { FormControl, MenuItem, Select } from '@mui/material';
// // import { Box } from '@mui/system';

// // const Navbar = () => {
// //   const { cart } = useCartContext();
  

// //   return (
// //     <div>
// //       <div style={{ display: 'flex', padding: 3 }} className='grad'>
// //         <p > <a style={{ color: 'black', fontSize: '20px', marginRight: 10 }} href='tel:+92 313 1688987'><CallIcon /> +92 313 1688987 </a></p>
// //         <p> <a style={{ color: 'black', fontSize: '20px' }} href='mailto:info@modestile.pk'> <Email /> info@modestile.pk</a></p>
// //       </div>
// //       <div style={{ background: 'black' }}>
// //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
// //           <div>
// //             <img src='images/logo.jpeg' width='25%' alt='Logo' />
// //           </div>
// //           <div style={{ display: 'flex' }}>
// //             <NavLink style={{ marginRight: '50%' }} to='/login' className='navbar-link cart-trolley--link'>
// //               <Person className='cart-trolley' fontSize='30px' />
// //             </NavLink>
// //             <NavLink style={{ marginRight: '50%' }} to='/cart' className='navbar-link cart-trolley--link'>
// //               <Heart className='cart-trolley' fontSize='30px' />
// //               <span className='cart-total--item'>
// //                 <div style={{ marginLeft: '28%', fontSize: '60%' }}>
// //                   {cart.length}
// //                 </div>
// //               </span>
// //             </NavLink>

// //             <NavLink to='/cart' className='navbar-link cart-trolley--link'>
// //               <FiShoppingCart className='cart-trolley' />
// //               <span className='cart-total--item'>
// //                 <div style={{ marginLeft: '28%', fontSize: '60%' }}>
// //                   {cart? cart.length:'0'}
// //                 </div>
// //               </span>
// //             </NavLink>
// //           </div>
// //         </div>
// //       </div>
// //       <div>
// //         <div style={{ display: 'flex', justifyContent: 'center' }} className='grad'>
// //           <NavLink to='/' style={{color:'black',backgroundColor:'transparent'}}>
// //           <h2 style={{ padding: 10, fontWeight: 'bold' }}>Home</h2>
// //           </NavLink>
// //           <NavLink to='/about' style={{color:'black',backgroundColor:'transparent'}}>
// //           <h2 style={{ padding: 10, fontWeight: 'bold' }}>About</h2>
// //           </NavLink>
          

// //             <Box sx={{ minWidth: 120, mt: 2 }}>
// //               <FormControl variant='standard' style={{outline:'none'}} fullWidth>
// //           <h2 style={{ padding: 10, fontWeight: 'bold', marginTop:'-15px' }}>Products</h2>
// //                 <Select
// //                 style={{marginTop:'-30px'}}
// //                   labelId="demo-simple-select-standard-label"
// //                   id="demo-simple-select-standard"
// //                   label="Sec"
// //                 >
// //                   <MenuItem value='male' selected='true'>
// //                     <NavLink to='/caps' style={{fontSize:20,fontWeight:'bold',background:'transparent',color:'black'}}>
// //                     Caps
// //                     </NavLink>
// //                     </MenuItem>
// //                   <MenuItem value='female'>
// //                     <NavLink to='/perfume' style={{fontSize:20,fontWeight:'bold',background:'transparent',color:'black'}}>
// //                     Perfumes
// //                     </NavLink>
// //                     </MenuItem>
// //                 </Select>
// //               </FormControl>
// //             </Box>
// //             <NavLink to='/contact' style={{color:'black',backgroundColor:'transparent'}}>
// //             <h2 style={{ padding: 10, fontWeight: 'bold' }}>Contact</h2>
// //             </NavLink>
// //           </div>
// //         </div>
// //       </div>
// //       )
// // }

// //       export default Navbar


















import React,{ useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './navbar.css'
import MenuIcon from '@mui/icons-material/Menu';
import Person from '@mui/icons-material/Person';
import { FiShoppingCart } from "react-icons/fi";
import modeloim from '../images/logo.png';
import { useCartContext } from '../context/cartContext';




 function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const {cart } = useCartContext();

  if(isNavExpanded == true){
      window.scrollTo(0, 0)
    
  }

  return (
    <nav className="navigation">
      <a href="/" className="brand-name">
        <img width='100px' src={modeloim} alt="" />
      </a>
      <button   className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div style={{paddingRight: '20px'}}
         className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="https://modestile.net" target='_blank'>USA</a>
          </li>
          <li>
            <a href="/shop">Shop</a>
          </li>
          <li>
            <a href="https://www.amazon.com/s?me=A1R86AL7BMPS7B" target='_blank'>Amazon</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          <li>
            <a href="/cart"><FiShoppingCart className='cart-trolley' /></a>
            
                <button className="btn-wishlist">
                  {cart.length}
                  </button> 
                
              
          </li>
          <li>
            <a href="/login"><Person  className='cart-trolley'/></a>
          </li>
        </ul>
      </div>
    
    </nav>
  );
}

export default Navbar
