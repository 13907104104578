import { Box, Container, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useState } from 'react'
import Footer from './Components/Footer'
import FullScreenLoader from './Components/fullScreenLoader'
import HeroSection from './Components/HeroSection'
import Navbar from './Components/Navbar'
import EmailForm from './Components/EmailForm'

const About = () => {
  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);

  return (
    <div style={{marginTop:120}}>
   
      <>
 <Navbar />
 <div className='about_us' style={{textAlign:'center',marginTop:40}}>
   <h2>About Us</h2>
   <h3 style={{marginTop:20}}>Modestile Islamic Kufi Hats</h3>
   <Container>
   <Box sx={{ flexGrow: 1, marginTop:'60px' }}>
      <Grid container spacing={2}>
        <Grid lg={6} md={6} sm={12} xs={12}>
        <p style={{marginTop:30,fontSize:20, textAlign:'left'}}>The story of Modestile with our 7 Years of experience of excellence Modestile continues its legacy of providing superior Islamic kufi hats for men's. versatile and competitive in market of this country and worldwide. <br/> <br/> 
<b>How we started? </b> <br/>
Modestile started with the unique idea of an for Islamic kufi hats best brand in the world.
When we started Modestile only had one worker and CEO in the company we started our work in a very single small room of solo machine and with less manufacturing equipment's.  <br/> <br/>  
Our ambition of hard work for an productss high quality stitching and quality maintaining strived the company company name in the today's world</p>
        </Grid>
        <Grid lg={6} md={6} sm={12} xs={12} style={{marginTop:'30px' }}>
          <img src='images/about-us.jpeg' alt='' height='400px'/>
        </Grid>
      </Grid>
    </Box>

   
  
 
   </Container>
 </div>
 <Footer />
 </>
   
   
    </div>
  )
}

export default About
