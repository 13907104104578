import React from 'react'
import {TbTruckDelivery} from 'react-icons/tb'
import {MdSecurity} from 'react-icons/md'
import {GiReceiveMoney} from 'react-icons/gi'
import {RiSecurePaymentLine} from 'react-icons/ri'
import styled from 'styled-components'
import { Box, Container } from '@mui/system'
import { Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

const Services = () => {
  const navigate = useNavigate()

  const category = ['KufiHats', 'Skin Care']

  const handleClick = () => {
    navigate(`/shop/${category[1]}`);
    window.location.reload()
  };
  return (
    <>
    <Wrapper>
   
    <Box sx={{ flexGrow: 1}} className="services-1">
        <div className='common-heading' style={{marginBottom:22}}>ISLAMIC ITEMS</div>
      <Container>
      <Grid container spacing={2} >
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <Link to={`/shop/${category[0]}`}>
        
         <img src='images/category 1.jpeg' width='80%'/>

         </Link>
          
        </Grid>
      
        <Grid item lg={6} md={6} sm={12} xs={12}>

       
        
        <img style={{cursor:'pointer'}} src='images/category 2.jpeg' onClick={handleClick} width='80%'/>

          
        </Grid>
      </Grid>
      </Container>
    </Box>
    </Wrapper>

    
    </>
  )
}
const Wrapper = styled.section`
  padding: 3rem 0;
  .grid {
    gap: 4.8rem;
  }
  .background{
    background: ${({ theme }) => theme.colors.bg};
    // border-radius: 2rem;
    // height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


   
  }
  .services-1,
  .services-2,
  .services-3 {
    width: auto;
    // height: 55rem;
    padding:20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: ${({ theme }) => theme.colors.bg};
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  .services-2 {
    gap: 4rem;
    background-color: transparent;
    box-shadow: none;
    .services-colum-2 {
      background: ${({ theme }) => theme.colors.bg};
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
    }
  }
  h3 {
    margin-top: 1.4rem;
    font-size: 2rem;
  }
  .icon {
    /* font-size: rem; */
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: #fff;
    color: #5138ee;
  }
`;
export default Services
