import { Box, CircularProgress, Grid, TextField } from '@mui/material'
import { Container } from '@mui/system'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'
import { CartProvider, useCartContext } from './context/cartContext'
import { getData, sendData } from './firebase/firebasemethod'
import { Button } from './styles/Button'
import ReactPixel from 'react-facebook-pixel';
import FacebookPixel from './Facebook'

const Checkout = () => {
  const { cart, clearCart, total_amount, shipping_fee, } = useCartContext();
  const location = useLocation()
  const order = {
    orderDetails: cart,
    
  }
  const [model, setModel] = useState(order,'')
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
console.log(location.state)

  const discountPrice = parseInt(location.state.discountRupees, 10)

  const [ trackPurchase ] = FacebookPixel({ pixelId: '197412553296937' });

  

 
 

  const sendOrder = ()=>{
    if (Object.values(model).length >= 5) {
      setLoader(true)
        sendData(model, 'ordersNew')
        .then((res) => {
          ReactPixel.track('Purchase', {
            content_ids: model.id, // Replace with actual product IDs from your order
            value: model.totalamount, // Replace with actual order total calculation
            currency: 'PKR', // Replace with actual currency
            content_type: cart.map(item => item.name)// Replace with actual content type if necessary
          });
            // Signed in 
            setLoader(false)
            navigate(`/thanks/${model.orderid}`)
            clearCart()
        })
        .catch((error) => {
                setLoader(false)
                const errorMessage = error.message;
                const errorMessageUp = errorMessage.toUpperCase();
                console.log(errorMessageUp)
            });
            
    } else {
        alert('ALL FIELDS MUST BE FILLED')
    }
  }

  let currency = 'PKR'

  const handleClick = () => {
    trackPurchase(model.totalamount, currency);  // Call the first function
    sendOrder()  // Call the second function
  };
  

  const date = new Date();

 
  let currentDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  console.log(currentDate)


  let orderId = Math.floor((Math.random() * 7612) + 500);

  // console.log(location.state)
  return (
    <div style={{marginTop:120}}>
    <Navbar />
    <Box sx={{textAlign:'center', marginTop:5}}>
    <h1>CHECKOUT</h1>
    </Box>
      <Box sx={{ flexGrow: 1, marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} xs={12} sm={12}>
            <Container>
            <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item lg={10} md={10} xs={12} sm={12}>
        <h2 style={{textAlign:'center'}}>Shipping Address</h2>
              <Box sx={{ flexGrow: 1, marginTop:5 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} sm={12} md={12} >
                    <h4><b>Email Address</b><span style={{fontSize:12,color:'gray'}}> (Optional)</span></h4>
                    <input onChange={e=>setModel({...model, email:e.target.value, code: location.state.code? location.state.code : null})} placeholder='xyz@example.com' style={{fontSize:12, width:'100%', marginTop:10}} color="success" fullWidth  className='inpu'  required variant="standard" />
                    {/* <input type="text" style={{fontSize: "20px"}} /> */}
                  </Grid> 
                  
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>First Name</b><span style={{color:'red'}}> *</span></h4>
                    <input onChange={e=>setModel({...model, firstname:e.target.value,status:'pending'})} placeholder='Umar' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>Last Name</b><span style={{color:'red'}}> *</span></h4>
                    <input onChange={e=>setModel({...model, lastname:e.target.value})} placeholder='Farooq' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={12} sm={12} md={12} >
                    <h4><b>Street Address</b><span style={{color:'red'}}> *</span></h4>
                    <input onChange={e=>setModel({...model, address:e.target.value,orderid:orderId, discountRupees: location.state.discountRupees? location.state.discountRupees : 0})} placeholder='122 Example St' style={{fontSize:12, width:'100%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>City</b><span style={{color:'red'}}> *</span></h4>
                    <input onChange={e=>setModel({...model, city:e.target.value,totalamount:location.state.totalPrice})} placeholder='Karachi' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>State/Province</b><span style={{fontSize:12,color:'gray'}}> (Optional)</span></h4>
                    <input onChange={e=>setModel({...model, province:e.target.value})} placeholder='Sindh' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>Country</b><span style={{fontSize:12,color:'gray'}}> (Optional)</span></h4>
                    <input onChange={e=>setModel({...model, country:e.target.value})} placeholder='Pakistan' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>Zip/Postal Code</b><span style={{fontSize:12,color:'gray'}}> (Optional)</span></h4>
                    <input onChange={e=>setModel({...model, zipcode:e.target.value})} type='number' placeholder='00000' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>Phone Number</b><span style={{color:'red'}}> *</span></h4>
                    <input type='number' onChange={e=>setModel({...model, number1:e.target.value, date:currentDate})} placeholder='03000000000' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    <h4><b>Phone Number 2</b><span style={{fontSize:12,color:'gray'}}> (Optional)</span></h4>
                    <input type='number' onChange={e=>setModel({...model, number2:e.target.value})} placeholder='03000000000' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>
                  <Grid item xs={12} lg={12} sm={12} md={12} >
                    <h4><b>Remarks</b><span style={{fontSize:12,color:'gray'}}> (Optional)</span></h4>
                    <textarea rows='10' cols='20' onChange={e=>setModel({...model, remarks:e.target.value})} placeholder='Remarks' style={{fontSize:12, width:'80%', marginTop:10}} color="success" fullWidth  className='input1'  required variant="standard" />
                  </Grid>

                  <Grid item xs={12} lg={6} sm={12} md={6} >
                    {
                      loader?(<Button><CircularProgress /></Button>):(<Button onClick={handleClick}>COMPLETE CHECKOUT AND ORDER</Button>)
                    }
                    
                    {/* <ModalExample /> */}
                  </Grid>
                 
                </Grid>
              </Box>
        </Grid>
      </Grid>
    </Box>
             
            </Container>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={12}>
            <Container>
               
            <Box>
              <div style={{borderWidth:'1px',borderColor:'black', borderStyle:'solid', padding:10}}>
                <h2 style={{textAlign:'center'}}>Current Cart</h2>
                <h4 style={{textAlign:'center', marginTop:20}}>Return to Cart</h4>
                {cart.map((e,i)=>{
                  return(
                    <>
                    <div style={{display:'flex', justifyContent:'space-between', marginTop:20}}>
                      <div>
                    <h4 style={{color:'#edc531'}}>{e.name}</h4>
                    <p style={{fontSize:12, marginTop:5}}>Size: {e.size}</p>
                      </div>
                      <div>
                        <h5>Rs: {e.price}</h5>
                      </div>
                    </div>
                    
                    </>
                  )
                })}<div style={{marginTop:35}}>
                <h3 style={{textAlign:'center'}}>Shipping Option</h3>
                <div style={{display:'flex', justifyContent:'center', marginTop:20}}>
                <select placeholder='Select' style={{padding:10, width:'100%'}}>
                  <option>Select Shipping Method</option>
                  <option>COD</option>
                </select>
                </div>
              </div>
              <div style={{marginTop:35}}>
                <h3 style={{textAlign:'center'}}>Cart Total</h3>
                <div style={{display:'flex', justifyContent:'center', marginTop:20}}>
                <h4>Rs: {location.state.totalPrice}</h4>
                </div>
              </div>
              </div>
            </Box>
            </Container>  
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  )
}

export default Checkout
