import React from 'react'

const CartReducer = (state, action) => {

    if (action.type === 'ADD_TO_CART') {
        let { id, color ,amount, product, size, category } = action.payload;
        console.log(id)

        if (product.category == 'Skin Care'){
          let existingProductSkinCare = state?.cart?.find((e)=> e.id == id)
          
          if (existingProductSkinCare) {

            let updatedAmount = state.cart.map((x) => {
                if (x.id == id) {
                    const newAmount = x.amount + amount;

                    // if (newAmount >= x.max) {
                    //     newAmount = x.max
                    // }
                    return {
                        ...x,
                        amount: newAmount
                    }
                } else {
                    return x;
                }

            })
            return {
                ...state,
                cart: updatedAmount,
            }
        } else {

            let cartProduct;

            cartProduct = {
                id: id,
                name: product.title,
               category: product.category,
                amount,
                image: product.image_link,
                price: product.sale_price?product.sale_price:product.price,
               
                
                // stock: product.stock
            }

           
                return {
                    ...state,
                    cart: [...state.cart, cartProduct],
                }
          

            
        }
        }else{

          let existingProduct = state?.cart?.find((e) => e.id == id + color + size)

          if (existingProduct) {
  
              let updatedAmount = state.cart.map((x) => {
                  if (x.id == id + color + size) {
                      const newAmount = x.amount + amount;
  
                      // if (newAmount >= x.max) {
                      //     newAmount = x.max
                      // }
                      return {
                          ...x,
                          amount: newAmount
                      }
                  } else {
                      return x;
                  }
  
              })
              return {
                  ...state,
                  cart: updatedAmount,
              }
          } else {
  
              let cartProduct;
  
              cartProduct = {
                  id: id + color + size,
                  name: product.title,
                  color,
                  amount,
                  category: product.category,
                  image: product.image_link,
                  price: product.sale_price?product.sale_price:product.price,
                  size,
                  
                  // stock: product.stock
              }
  
             
                  return {
                      ...state,
                      cart: [...state.cart, cartProduct],
                  }
            
  
              
          }
        }

    }

    


    if (action.type === 'CLEAR_CART') {
        return {
            ...state,
            cart: [],
        }
    }

    if (action.type === "REMOVE_ITEM") {
        let updatedCart = state.cart.filter(
          (curItem) => curItem.id !== action.payload
        );
        return {
          ...state,
          cart: updatedCart,
        };
      }
      

      if (action.type === "CART_ITEM_PRICE_TOTAL") {
        if(state.cart){
          let { total_item, total_amount } = state?.cart?.reduce(
            (accum, curElem) => {
              let { price, amount } = curElem;
      
              accum.total_item += amount;
              accum.total_amount += price * amount;
      
              return accum;
            },
            {
              total_item: 0,
              total_amount: 0,
            }
          );
          return {
            ...state,
            total_item,
            total_amount,
          };
        }
        
        
      }

      
    

    return state;
}

export default CartReducer
