// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBOEJw9dBAQTtiVqxLW-3gfAn3D6T2g9VQ",
  authDomain: "modestile.firebaseapp.com",
  projectId: "modestile",
  storageBucket: "modestile.appspot.com",
  messagingSenderId: "869409948974",
  appId: "1:869409948974:web:11ab5003fbc797f991e6bf",
  measurementId: "G-9Y3CW24WN7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;