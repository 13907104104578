import { SportsCricketOutlined } from '@mui/icons-material'
import { Button, Card, CardOverflow, Link } from '@mui/joy'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import FacebookPixel from '../Facebook';

const MainProduct = (item) => {
  const { pId, name, image, sPrice, desc, price } = item
  const navigate = useNavigate()

  const handleClick = (item) => {
    navigate(`/product/${pId}`, {
      state: item

    })

    const [trackViewContent ] = FacebookPixel({ pixelId: '197412553296937' });

    const handleViewContent = () => {
      // Call the trackViewContent function to track ViewContent event
      trackViewContent(pId, name, 'Kufi Hats', sPrice?sPrice:price); // Assuming these are the parameters you want to track
    };

    // console.log(`data=====> ${item}`)
  }

  // console.log(item)

  return (
    <div style={{ marginTop: 10 }}>
      <Card>
        <div>
          <div onClick={() => handleClick(item)} key={pId} className='card' style={{ padding: 3 }}>
            <figure>
              <img src={image} width='100%' />
              {sPrice ?
                <button className="product-card__btn-wishlist">Sale</button> :
                null
              }
            </figure>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='card-data' style={{ width: '90%', marginTop: 5 }}>
                {/* <h3 style={{ fontSize: 20, marginTop: 10, textAlign:'center' }}>{name}</h3> */}

                <Link
                  onClick={() => handleClick(item)}
                  fontWeight="md"
                  color="neutral"
                  textColor="text.primary"
                  overlay
                  endDecorator={<ArrowOutwardIcon />}
                  style={{ fontSize: 20 }}
                >
                  {name}
                </Link>

                <div>
                <h3 style={{ fontSize: 15, marginTop: 10 }}>{sPrice ? `Rs:${sPrice}` : `Rs:${price}`} <s style={{ color: 'gray' }}>{sPrice ? `Rs:${price}` : null}</s></h3>
                  {/* <div onClick={() => handleClick(item)} className='box' style={{ backgroundColor: 'black', borderRadius: 20, marginTop: 15,padding:5,marginBottom:10, cursor:'pointer' }}>
          <button className='productBtn' >Shop Now</button>
            </div> */}


                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CardOverflow onClick={() => handleClick(item)}>
          <Button style={{ fontSize: 15 , backgroundColor:'#efb113' }} variant="solid" size="lg">
            Shop Now
          </Button>
        </CardOverflow>
      </Card>
      {/* <ModalExample button='Quick View'/> */}
    </div>
  )
}

export default MainProduct
