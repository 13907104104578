import React, { useState } from "react";
import {  CircularProgress, Divider, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../firebase/firebasemethod";
import './main.css'
import { Button } from "../styles/Button";

// import { useDispatch } from "react-redux";
const LoginU = () => {
  const [model, setModel] = useState({});
  let navigate = useNavigate();
  // const dispatch = useDispatch()
  const[isLoading, setLoading] = useState(false)

  

  let login = () => {
    setLoading(true)
    loginUser(model).then((res)=>{
      setLoading(false)

     

      if(res.category=='admin'){
        navigate('/a5fgh4-0565-hgseu-4s5as-s54s54d5sh5ggs')
        localStorage.setItem('login',true)
      } else if (res.category == 'user') {
        navigate(`/`,{
          state:res
        })
      }
    })
    .catch((err)=>{
      setLoading(false)
      alert(err)
    })
  };
  let clickEv = () => {
    navigate("/signup");
  };
// console.log(model)
  return (
    <>
    <div className="container containerl">
      <div className="main">
        <Box sx={{ mb: 2 }}>
          <img src='images/logo.png' alt="TodoLogo" width="50%" />
        </Box>
        <h3>Login to your account</h3>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ pb: 2 }}>
              <input
                placeholder="Email"
                onChange={(e) => setModel({...model, email: e.target.value})}
                type="email"
                // variant="standard"
                // sx={{ width: "90%" }}
                style={{border:'none',borderBottom:'1px solid black'}}
              size="70"
              />
            </Box>
            <Box sx={{ pb: 2 }}>
              <input
                placeholder="Password"
                onChange={(e) => setModel({...model, password: e.target.value})}
                type="password"
                style={{border:'none',borderBottom:'1px solid black'}}
                // variant="standard"
                size="70"
                // sx={{ width: "90%" }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Button style={{width:'90%'}} className="button" variant="contained" onClick={login} fullWidth>
            {isLoading? <CircularProgress color="inherit" />:"Login" }
            </Button>
          </Box>
          <h3>
            Need a member? <span style={{color:'#926c15'}} onClick={clickEv}>Sign Up</span>
          </h3>
          <h3 style={{marginTop:20}}>
          If you want to login later <span style={{color:'#926c15'}} onClick={()=>{navigate('/')}}>Back Home</span>
          </h3>
        </Box>
        
      </div>
    </div>
  </>
  );
};

export default LoginU;