import { Carousel } from 'react-carousel-minimal';
import './data.css'

function ImageSlider() {
 const data = [
  {
    image: "images/inayatS1.jpg",
    // caption: "San Francisco"
  },
  {
    image: "images/Slider-1.jpeg",
   //  caption: "Click Here"
  },
    
    {
      image: "images/BadarS2.jpeg",
      // caption: "Scotland"
    },
    
    {
      image: "images/saeedS2.jpeg",
      // caption: "Scotland"
    },
    {
      image: "images/SiryaS5.png",
      // caption: "Scotland"
    },
    {
      image: "images/AttarS3.jpeg",
      // caption: "Scotland"
    },
   
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div style={{marginTop:110}} className="App">
      <div style={{ textAlign: "center" }}>
       
        <div style={{
          // padding: "0 20px"
        }}>
          <Carousel
          className='Slider'
            data={data}
            time={3000}
            width="100%"
            marginTop='10px'
            // height="600px"
            captionStyle={captionStyle}
            // radius="10px"
            // slideNumber={true}
            // slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            // thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              //  maxWidth: "850px",
              //  maxHeight: "500px",
              margin: " auto",
              // marginBottom:'180px'
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;