import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import AddToCart from "./Components/AddToCart";
import Footer from "./Components/Footer";
import MyImage from "./Components/MyImage";
import Navbar from "./Components/Navbar";
import PageNavigation from "./Components/PageNavigation";
import { getData } from "./firebase/firebasemethod";
import FullScreenLoader from "./Components/fullScreenLoader";
import { Helmet } from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import { ExportToExcel } from "./Admin Screen/DScreen/ExporttoExcel";


const SingleProduct = () => {
  const location = useLocation()
  const [data, setData] = useState([])
  let [cart, setCart] = useState([])
  const [loader, setLoader] = useState(true)
  const { id } = useParams()
  console.log(id)
  const [product, setProduct] = useState();
  // console.log(cart)
  // console.log(data)





  useEffect(() => {

    getData(`Products/${id}`).then((res) => {
      console.log('product get')
      setProduct(res)
      setLoader(false)
    }).catch((err) => {
      console.log('product not get ', err)
    })
  }, [])


  

  console.log(product)

  const productData = {
    id: product && product[4],
    title: product && product[3],
    price: product && product[5],
    sale_price: product && product[7] ? product && product[6] : null,
    description: product && product[1],
    availability: product && product[7] ? product && product[7] : product && product[6],
    condition: 'new',
    link: `https://modestile.pk/product/${id}`,
    image_link: product && product[2],
    brand: 'Modestile',
    category: product && product[0],
  }


  useEffect(() => {
    // Track product view event
    ReactPixel.track('ViewContent', {
      content_ids: productData.id, // Array of product IDs (REQUIRED)
      content_type: productData.title // Type of content being tracked (RECOMMENDED)
    });
  }, [productData.id]);





  console.log(productData)

  return (
    <>
      {loader ? (<FullScreenLoader />) : (
        <div style={{ marginTop: 110 }}>

          <Navbar />

          <Wrapper >
            <div className="application">
              <Helmet>
                <meta property="og:title" content={productData.title} />
                  <meta property="og:description" content={productData.description} />
                    <meta property="og:url" content={productData.link} />
                      <meta property="og:image" content={productData.image_link} />
                        <meta property="product:brand" content={productData.brand} />
                          <meta property="product:availability" content="in stock" />
                            <meta property="product:condition" content="new" />
                              <meta property="product:price:amount" content={productData.price}/>
                                <meta property="product:price:currency" content="PKR" />
                                  <meta property="product:retailer_item_id" content={productData.id} />
                                    </Helmet>

                                  </div>
                                  <PageNavigation hamza={productData.title} />
                                  <Box sx={{ flexGrow: 1, mt: 10 }} >
                                    <Container>
                                      <Grid container spacing={2}>
                                        <Grid item lg={6}>
                                          {/* product image */}
                                          <div className="product-images">
                                            {/* <MyImage imgs={data.image}/> */}
                                            <img src={productData.image_link} width='80%' />
                                          </div>
                                        </Grid>
                                        <Grid item lg={6} sx={{ mt: 10 }}>
                                          {/* product data */}

                                          <div className="product-data">
                                            <h1>{productData.title}</h1>
                                            {/* <p className="product-data  -price">Price:
            <del>
              
              {data.price}
              </del>
              </p> */}
                                            <p className="product-data-price product-data-real-price">
                                              Price: {productData.sale_price ? `${productData.sale_price}` : `${productData.price}`} <s style={{ color: 'gray' }}>{productData.sale_price ? `Price${productData.price}` : null}</s>
                                            </p>
                                            <h4>{productData.description}</h4>
                                            <hr />
                                            <div className="product-data-info">

                                              <h6>ID: <span style={{fontWeight:50}}>{productData.id}</span></h6>
                                              <h6>Category: <span style={{fontWeight:50}}>{productData.category}</span></h6>
                                              <h6>Brand: <span style={{fontWeight:50}}>{productData.brand}</span></h6>
                                            </div>
                                            <hr />
                                            <AddToCart cart={cart} product={productData} />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Container>
                                  </Box>
                                </Wrapper>


                                <Footer />

                              </div>
    )}
                            </>
                            )

}
                            const Images = styled.section`
                            display: grid;
                            grid-template-columns: 0.4fr 1fr;
                            gap: 1rem;
                            .grid {
                              flex - direction: row;
                            justify-items: center;
                            align-items: center;
                            width: 100%;
                            gap: 1rem;
                            /* order: 2; */
                            img {
                              max - width: 100%;
                            max-height: 100%;
                            background-size: cover;
                            object-fit: contain;
                            cursor: pointer;
                            box-shadow: ${({ theme }) => theme.colors.shadow};
    }
  }
                            .main-screen {
                              display: grid;
                            place-items: center;
                            order: 1;
                            img {
                              max - width: 100%;
                            height: auto;
                            box-shadow: ${({ theme }) => theme.colors.shadow};
    }
  }
                            .grid-four-column {
                              grid - template - columns: 1fr;
                            grid-template-rows: repeat(4, 1fr);
  }
                            @media (max-width: ${({ theme }) => theme.media.mobile}) {
                              display: flex;
                            flex-direction: column;
                            order: 1;
                            .grid-four-column {
                              grid - template - rows: 1fr;
                            grid-template-columns: repeat(4, 1fr);
    }
  }
                            `;

                            const Wrapper = styled.section`
                            .container {
                              padding: 9rem 0;
  }
                            .product-data {
                              display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            gap: 2rem;

                            .product-data-warranty {
                              width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #ccc;
                            margin-bottom: 1rem;

                            .product-warranty-data {
                              text - align: center;

                            .warranty-icon {
                              background - color: rgba(220, 220, 220, 0.5);
                            border-radius: 50%;
                            width: 4rem;
                            height: 4rem;
                            padding: 0.6rem;
        }
                            p {
                              font - size: 1.4rem;
                            padding-top: 0.4rem;
        }
      }
    }

                            .product-data-price {
                              font - weight: bold;
                            font-size:20px
    }
                            .product-data-real-price {
                              color: ${({ theme }) => theme.colors.btn};
    }
                            .product-data-info {
                              display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            font-size: 1.8rem;

                            span {
                              font - weight: bold;
      }
    }

                            hr {
                              max - width: 100%;
                            width: 90%;
                            /* height: 0.2rem; */
                            border: 0.1rem solid #000;
                            color: red;
    }
  }

                            .product-images {
                              display: flex;
                            justify-content: center;
                            align-items: center;
  }

                            @media (max-width: ${({ theme }) => theme.media.mobile}) {
                              padding: 0 2.4rem;
  }
                            `;

                            export default SingleProduct;
