import React, { useState } from 'react'
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa'
import { useCartContext } from '../context/cartContext'
import CardToggleAmount from './CardToggleAmount'


const CartItem = ({id, name, image,  color, price, amount,size, category}) => {
  const {cart, removeItem} = useCartContext();

  // const [list, setList] = useState([cart])
  // const [amounts, setAmount] = useState(amount)

  // const del = (id) => {
  //   cart.splice(id,1);
  //   console.log(id)
  // }

  // const setIncreament = (id)=>{
  //   amount + 1
  // }
  // const setDecreament = (id)=>{
  //   amount + 1
  // }

  console.log(price)

  return (
    <>
      <div className="cart-heading grid grid-five-column">
        <div className="cart-image--name">
          <div>
            <figure>
              <img src={image} alt={id} />
            </figure>
          </div>
          {category == 'KufiHats'?(<div>
          <p style={{fontSize:13}}>{name}</p>
        <div className="color-div" style={{marginTop:5}}>
          <p style={{fontSize:13}}>color:</p>
          <div className='color-style' style={{backgroundColor:color}}></div>
        </div>
        <div className="color-div">
          <p style={{fontSize:13}}>size:</p>
          <p style={{fontSize:13}} className='color-style'>{size}</p>
        </div>
          </div>):(<div>
            <p style={{fontSize:13}}>{name}</p>
          </div>)}
          
        </div>
        
        <div className='cart-hide'>
          <p style={{fontSize:13}}>{price}</p>
        </div>

        <div>
        <>
    <div className='cart-button'>
        <div className='amount-toggle'>
           
            <p style={{fontSize:25}} className='amount-style'>{amount}</p>
            
        </div>
    </div>
    </>
        </div>

        <div className="cart-hide">
          <p style={{fontSize:13}}>{price * amount}</p>
        </div>

        <div>
          <FaTrash className='remove_icon' onClick={()=> removeItem(id)}/>
        </div>
      </div>
    </>
  )
}

export default CartItem
